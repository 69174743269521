// useMutationApi.js

import { useMutation } from '@apollo/client'
import { gql } from 'graphql-tag'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { actionsUsers } from '../setup/redux/rootReducer'
import { useState } from 'react'

const useMutationApi = (mutation: any) => {
  const dispatch = useDispatch()
  const [errorMsg, seterrorMsg] = useState(false)

  const [createDataMutation, { loading, error, data }]: any = useMutation(mutation)
  
  const createData = async (input: any) => {
    seterrorMsg(false)
    try {
      const response = await createDataMutation({
        variables: input,
      })

      // Do something with the response if needed

      return response
    } catch (error: any) {
      // Handle error
      if (error && !errorMsg) {
        seterrorMsg(true)
        const sessionExpirePattern = /session\s*expire/i
        // if (sessionExpirePattern.test(error?.message)) {
        //   toast.error('Session Expired! Please login again.')
        //   setTimeout(() => {
        //     dispatch(actionsUsers.logout())
        //   }, 3000)
        // } else {
        //   toast.error(`${error?.message}`)
        // }

        if (sessionExpirePattern.test(error?.networkError?.result?.errors[0]?.message)) {
          toast.error('Session Expired! Please login again.')
          setTimeout(() => {
            dispatch(actionsUsers.logout())
          }, 3000)
        } else {
          toast.error(`${error?.message}`)
        }

        // if (error?.networkError?.statusCode === 440) {
        //   toast.error(`${error?.networkError?.result?.errors[0]?.message}`)
        //   setTimeout(() => {
        //     dispatch(actionsUsers.logout())
        //   }, 3000)
        // } else {
        //   toast.error(`${error?.message}`)
        // }
      }
      throw error
    }
  }

  return {
    createData,
    loading,
    error,
    data,
  }
}

export default useMutationApi
