/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { useFormik } from 'formik'
import { useAuth } from '../core/Auth'
import { useSelector, useDispatch } from 'react-redux'

import { useLocation, useNavigate } from 'react-router-dom'
import { OTP_MUTATION, RESEND_OTP } from '../../../GraphQLQuries/Auth'
import useMutationApi from '../../../../../hooks/useMutation'
import { actionsUsers } from '../../../../../setup/redux/rootReducer'
import { toast } from 'react-toastify'
import { ToastMessageContainer } from '../../../../../_ePulse/layout/components/ToastMessageContainer'

const loginSchema = Yup.object().shape({
  otp: Yup.string().required('OTP is required'),
})

const initialValues = {
  otp: '',
}

export function OTP() {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const { browserUniqueId, ipAddress, userAgent, browserName, countryCode, resendOtpTime }: any =
    location?.state
  const userData: any = useSelector((state) => {
    return state
  })
  const [minutes, setMinutes] = useState(resendOtpTime.replace('m', ''))
  const [seconds, setSeconds] = useState(0)
  const [showToast, setToast] = useState(true)
  const [loadingLoader, setLoading] = useState(false)

  useEffect(() => {
    // if (process.env.REACT_APP_ENV === 'UAT' && showToast) {
    //   toast.success('OTP is send to your registered mobile number')
    //   setToast(false)
    // }                                                                      //OTP TOAST
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1)
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval)
        } else {
          setSeconds(59)
          setMinutes(minutes - 1)
        }
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [seconds])

  const { createData, data: data1, loading: loading1, error: error1 } = useMutationApi(OTP_MUTATION)

  useEffect(() => {
    if (data1) {
      dispatch(actionsUsers.updateState(data1.verifyWebAdminOtp))
      dispatch(
        actionsUsers.updateUser({
          mobile_number: userData.user.mobile_number,
          _id: data1.verifyWebAdminOtp.userId,
        })
      )
      navigate(`/auth/business`, {
        state: { browserUniqueId, ipAddress, userAgent, browserName },
      })
    }
  }, [data1])

  // const [verifyOtp, {data, loading, error}] = useMutation(OTP_MUTATION, {
  //   onCompleted: (response: any) => {
  //     dispatch(actionsUsers.updateState(response.verifyWebAdminOtp))
  //     dispatch(
  //       actionsUsers.updateUser({
  //         mobile_number: userData.user.mobile_number,
  //         _id: response.verifyWebAdminOtp.userId,
  //       })
  //     )
  //   },
  // })

  const {
    createData: resendOtp,
    data: resendotpData,
    loading: resendotpLodaing,
    error: resendotpError,
  } = useMutationApi(RESEND_OTP)

  const resend = async () => {
    const res = await resendOtp({
      mobileNumber: userData.user.mobile_number,
      countryCode: parseInt(countryCode),
    })

    alert(res.data.resendOtp)
  }

  const [status, setstatus] = useState(false)
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (
      values
      // , {setStatus, setSubmitting}
    ) => {
      setLoading(true)
      try {
        const mobileNumber = userData.user.mobile_number
        // await verifyOtp({
        //   variables: {
        //     mobileNumber,
        //     countryCode: parseInt(countryCode),
        //     otp: values.otp,
        //     type: 'OTP',
        //     browserUniqueId: browserUniqueId,
        //     ipAddress: ipAddress,
        //     userAgent: userAgent,
        //     browserName: browserName,
        //   },
        // })
        const res = await createData({
          mobileNumber,
          countryCode: parseInt(countryCode),
          otp: values.otp,
          type: 'OTP',
          browserUniqueId: browserUniqueId,
          ipAddress: ipAddress,
          userAgent: userAgent,
          browserName: browserName,
        })

        setLoading(false)
      } catch (error) {
        setstatus(true)
        setLoading(false)
      }
    },
  })

  // if (data) {
  //   dispatch(actionsUsers.updateState(data.verifyOtp))
  // }


  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      <ToastMessageContainer />
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>
          Enter OTP recieved on your mobile number
          {/* <a className='' style={{color: '#000FD0'}}>
            ePulse
          </a> */}
        </h1>
        {/* <div className='text-gray-400 fw-bold fs-4'>
          New Here?{' '}
          <Link to='/auth/registration' className=' fw-bolder' style={{color: '#000FD0'}}>
            Create an Account
          </Link>
        </div> */}
      </div>
      {/* begin::Heading */}

      {status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{error1?.message}</div>
        </div>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>OTP</label>
        <input
          placeholder='Enter OTP'
          {...formik.getFieldProps('otp')}
          className={clsx('form-control form-control-lg form-control-solid')}
          type='text'
          name='otp'
          autoComplete='off'
          maxLength={6}
        />
        {formik.touched.otp && formik.errors.otp && (
          <div className='fv-plugins-message-container'>
            <span className='text-danger'>{formik.errors.otp}</span>
          </div>
        )}
      </div>
      <div className='d-flex flex-row justify-content-between mb-3 align-items-center'>
        {seconds > 0 || minutes > 0 ? (
          <p>
            Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
            {seconds < 10 ? `0${seconds}` : seconds}
          </p>
        ) : (
          <p>Didn't recieve code?</p>
        )}

        <button
          disabled={seconds > 0 || minutes > 0}
          style={{
            color: seconds > 0 || minutes > 0 ? '#DFE3E8' : '#FF5630',
          }}
          onClick={() => resend()}
        >
          Resend OTP
        </button>
      </div>

      {/* end::Form group */}
      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg  w-100 mb-5 rounded-pill text-white'
          //   disabled={formik.isSubmitting || !formik.isValid}
          style={{ backgroundColor: '#000FD0' }}
        >
          {!loadingLoader && <span className='indicator-label'>Continue</span>}
          {loadingLoader && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
    </form>
  )
}
