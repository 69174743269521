import {gql} from '@apollo/client'

export const OTP_MUTATION = gql`
  mutation VerifyOtp(
    $countryCode: Int!
    $mobileNumber: MobileNumber!
    $otp: String!
    $type: pinType!
    $ipAddress: String
    $userAgent: String
    $browserUniqueId: String!
    $browserName: String
  ) {
    verifyWebAdminOtp(
      countryCode: $countryCode
      mobileNumber: $mobileNumber
      otp: $otp
      type: $type
      deviceDetail: {
        ipAddress: $ipAddress
        userAgent: $userAgent
        browserUniqueId: $browserUniqueId
        browserName: $browserName
      }
    ) {
      userId
      tokenType
      token
      role
      roleId
      profileData {
        firstName
        lastName
        email
        phone
      }
    }
  }
`

export const WEB_ADMIN_LOGIN = gql`
  mutation WebAdminLogin(
    $mobileNumber: MobileNumber!
    $countryCode: Int!
    $ipAddress: String
    $userAgent: String
    $browserUniqueId: String!
    $browserName: String
  ) {
    webAdminLogin(
      webAdminLogin: {
        countryCode: $countryCode
        mobileNumber: $mobileNumber
        deviceDetail: {
          ipAddress: $ipAddress
          userAgent: $userAgent
          browserUniqueId: $browserUniqueId
          browserName: $browserName
        }
      }
    ) {
      resendOtpTime
      otp
      pinLength
    }
  }
`

export const RESEND_OTP = gql`
  mutation resendOtp($countryCode: Int!, $mobileNumber: MobileNumber!) {
    resendOtp(sendOtpInput: {countryCode: $countryCode, mobileNumber: $mobileNumber})
  }
`

export const GET_MPIN_LENGTH = gql`
  query getMpinLengthFromMobileNumber(
    $countryCode: Int!
    $mobileNumber: MobileNumber!
    $ipAddress: String
    $userAgent: String
    $browserUniqueId: String!
    $browserName: String
    $deviceType: deviceType!
  ) {
    getMpinLengthFromMobileNumber(
      loginRegInput: {
        countryCode: $countryCode
        mobileNumber: $mobileNumber
        deviceDetail: {
          deviceType: $deviceType
          ipAddress: $ipAddress
          userAgent: $userAgent
          browserUniqueId: $browserUniqueId
          browserName: $browserName
        }
      }
    )
  }
`

export const GET_BUSINESS_LIST_AUTH = gql`
  query getWebBusinessList {
    getWebBusinessList {
      id
      userId
      applicationRole
      businessProfileRole
      businessProfileSubRole
      businessType
      businessId
      isUserVerified
      isBusinessVerified
      associatedName
      isApproved
      isActive
      isDefaultBusiness
    }
  }
`

